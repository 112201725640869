import React from 'react'
import { Title } from './Title'
import price from '../assets/price.png'
export const Plans = () => {
    const pricing = [
        {
          id: 1,
          title: 'Basic',
          price: '199',
          description: 'Perfect for getting started with essential features.',
          list: [
            'Logo Design',
            'Responsive Layout',
            '5 Day Delivery',
            '2 Revisions',
            'Web Templates',
            'Source Code',
            '$40/Monthly Fees',
            '"YOU DO NOT OWN THE WEBSITE"'
          ],
        },
        {
          id: 2,
          title: 'Standard',
          price: '499',
          description: 'Ideal for businesses looking to establish a solid online presence.',
          list: [
            'Logo Design',
            'Responsive Layout',
            '14-Day Delivery',
            '5 Revisions',
            'Website Templates/Custom made',
            'Source Code',
            'Hosting',
            'Domain Registration',
            '1 Month Free Support',
            'One-time Payment',
            '"YOU DO NOT OWN THE WEBSITE"',
            '$19.99/Monthly Fees'
          ],
        },
        {
          id: 3,
          title: 'Premium',
          price: '2499',
          description: 'Comprehensive package with extended support and flexible payment options.',
          list: [
            'Logo Design',
            'Responsive Layout',
            '30-Day Delivery',
            '7 Revisions',
            'Figma Files',
            'Source Code',
            '6 Months Free Support',
            'Hosting',
            'Domain Registration',
            '3 Installment Payments',
            '"YOU OWN THE WEBSITE"',
            'No monthly fees'
          ],
        },
      ];
      
  return (
    <div className="my-20 bg-[#1D2129]" id='pricing'>
      <h1 className='text-white text-2xl font-semibold text-center pt-4'>Our pricing</h1>
      <p className='opacity-60 py-2 pb-4 lg:w-[40%] text-white mx-auto text-center'>We are with full transparency, if we agreed on the right price from the start our work will be smooth.</p>
      {/* <img className='w-1/2 mx-auto rounded-xl' src={price} alt="" /> */}
    
    </div>
  )
}
