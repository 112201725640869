import React from "react";
import blogs from "./blogsData";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
export const BlogSection = () => {
    const navigate = useNavigate();

  return (
    <div className="max-w-7xl mx-auto py-10 px-4">
      <div className="bg-gray-200 px-4 py-3 rounded-lg mb-8">
        <h1 className="text-center uppercase pb-2">Welcome to Blog</h1>
        <h2 className="text-2xl text-center lg:w-1/2 mx-auto">
          Craft narratives that ignite inspiration, knowledge, and
          entertainment.
        </h2>
      </div>
      {blogs
        .filter((blog) => blog.id === 1) // Filters out the blog with id === 1
        .map((blog) => (
          <div key={blog.id}>
            <a href={blog.link}  onClick={() =>
                navigate(`${blog.link}`, { state: { blog } }) // Pass blog data as state
              }>
              <article className="rounded-lg pb-8">
                <div className="md:flex md:space-x-8 lg:h-80">
                  <img
                    className="rounded-xl w-full lg:w-1/2 h-full object-cover"
                    src={blog.image}
                    alt={blog.title}
                  />
                  <div className="w-full lg:w-1/2">
                    <h2 className="text-3xl font-semibold mb-4">
                      {blog.title}
                    </h2>
                    <p className="text-gray-700 mb-4">{blog.description}</p>
                    <p className="font-sm text-gray-600">{blog.readTime}</p>
                  </div>
                </div>
              </article>
            </a>
          </div>
        ))}
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-semibold pb-4">Latest Blogs</h2>
        <div className="flex items-center space-x-2">
          <a href="/all-blogs">See all</a>
          <button>
            <FaArrowRight />
          </button>
        </div>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {blogs
          .filter((blog) => blog.id !== 1)
          .slice(0, 4)
          .map((blog) => (
            <a href={blog.link}  onClick={() =>
                navigate(`${blog.link}`, { state: { blog } }) // Pass blog data as state
              }>
              <article className="rounded-lg">
                <div className="">
                  <img
                    className="rounded-xl h-full object-cover"
                    src={blog.image}
                    alt=""
                  />
                  <div className="">
                    <h2 className="text-xl font-semibold py-2">
                      {blog.title}
                    </h2>
                    <p className="text-gray-700 mb-4">{blog.description}</p>
                    <p className="font-sm text-gray-600">{blog.readTime}</p>
                  </div>
                </div>
              </article>
            </a>
          ))}
      </div>
    </div>
  );
};
