import React from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const ComparisonChart = () => {
  const services = [
    {
      name: "Custom Design",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Ease of Use",
      websiteBuilder: true,
      otherAgency: false,
      myService: true,
    },
    {
      name: "SEO Optimization",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Maintenance & Updates",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Mobile Optimization",
      websiteBuilder: true,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Support & Customer Service",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "E-Commerce Integration",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Security & Backup",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
    {
      name: "Load Speed & Performance",
      websiteBuilder: false,
      otherAgency: false,
      myService: true,
    },
  ];

  return (
    <div className="my-12 pt-24 px-4 md:px-10 max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-8">Why Choose Us?</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-lg font-semibold">
                Service
              </th>
              <th className="px-6 py-3 text-lg font-semibold">
                Website Builders
              </th>
              <th className="px-6 py-3 text-lg font-semibold">
                Other Agency Websites
              </th>
              <th className="px-6 py-3 text-lg font-semibold">
                Why Choose Us?
              </th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="px-6 py-4">{service.name}</td>
                <td className="px-6 py-4 text-center">
                  {service.websiteBuilder ? (
                    <AiOutlineCheck className="text-green-500 mx-auto" />
                  ) : (
                    <AiOutlineClose className="text-red-500 mx-auto" />
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {service.otherAgency ? (
                    <AiOutlineCheck className="text-green-500 mx-auto" />
                  ) : (
                    <AiOutlineClose className="text-red-500 mx-auto" />
                  )}
                </td>
                <td className="px-6 py-4 text-center bg-[#1D2129]">
                  {service.myService ? (
                    <AiOutlineCheck className="text-green-500 text-2xl mx-auto" />
                  ) : (
                    <AiOutlineClose className="text-red-500 mx-auto text-2xl" />
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {service.name === "Pricing" ? (
                    <span className="text-xl font-bold text-blue-600">
                      One-time: $199 | Monthly: $150
                    </span>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}

            {/* Add Pricing Row for Website Builder */}
            <tr className="border-t border-gray-300">
              <td className="px-6 py-4 text-center font-semibold">Pricing</td>
              <td className="px-6 py-4 text-center">
                <span className="text-xl font-bold text-blue-600">
                  Starting from $39.99/month
                </span>
              </td>
              <td className="px-6 py-4 text-center">
                <span className="text-xl font-bold text-blue-600">
                  One-time: $500 - $6000 Starting from $250/month
                </span>
              </td>
              <td className="px-6 py-4 text-center">
                <span className="text-xl font-bold text-blue-600">
                  One-time: $199 | Monthly: $150
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

   
    </div>
  );
};

export default ComparisonChart;
