import "./App.css";
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/Navbar";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import Rights from "./components/Rights";
import AOS from "aos";
import "aos/dist/aos.css";
import MousePointer from "./components/MousePointer";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import TermsOfServices from "./components/TermsOfServices";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { MoreWork } from "./pages/MoreWork";
import { Helmet } from "react-helmet-async";
import { BlogSection } from "./components/BlogSection";
import { BlogDetails } from "./components/BlogDetails";
import { AllBlogs } from "./components/AllBlogs";

function App() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [displayText, setDisplayText] = useState("");
  // const location = useLocation();


  // ReactGA.initialize("UA-XXXXXXXXX-X");
  // ReactGA.pageview(window.location.pathname + window.location.search);


  useEffect(() => {
    // ReactGA.pageview(location.pathname + location.search);
    // Initialize AOS
    AOS.init();
  }, []);

  

  return (
    <div  className="App bg-[#FBFBFB]">
      {/* bg-[#1b2a41] text-[#e1e5f2] */}
      <Helmet>
        <title>Home</title>
        <meta name="description" content="This is my home page that shows what I do and what I charge in Website Development." />
      </Helmet>
      <div className="">
        <BrowserRouter>
          {/* Render Navbar and Routes */}
          <Navbar />
          <Routes>
            <Route
              path={"/"}
              element={<Home setDisplayText={setDisplayText} />}
            />
            <Route path={"/more-projects"} element={<MoreWork />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/blog"} element={<BlogSection />} />
            <Route path={"/all-blogs"} element={<AllBlogs />} />
            <Route path={"/blog-details/:id"} element={<BlogDetails />} />
            <Route path={"/terms-of-service"} element={<TermsOfServices />} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          </Routes>
          {/* Render Footer and Rights components */}
          <Footer />
       
          {/* Render MousePointer component */}
          {/* <MousePointer displayText={displayText} position={position} /> */}
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
