import React from "react";
import { FaCheckCircle, FaThumbtack, FaUsers, FaDollarSign, FaChartLine, FaComments } from 'react-icons/fa';

export const Why = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 sm:px-12 max-w-7xl mx-auto">
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-4xl font-semibold text-gray-800 mb-6">
          Why Your Business Needs a Website
        </h2>
        <p className="text-lg text-gray-600 mb-12">
          In today’s digital world, having a website is no longer optional — it’s a necessity. Here’s why you need one:
        </p>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        <div className=" p-6 rounded-lg shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaCheckCircle className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Establish Credibility and Trust</h3>
          <p className="text-gray-600">A professionally designed website builds credibility for your business. In an era where customers expect to find businesses online, having a website helps establish your authority in your industry.</p>
        </div>

        <div className=" p-6 rounded shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaThumbtack className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">24/7 Accessibility</h3>
          <p className="text-gray-600">Your website is available around the clock, allowing customers to learn about your products, services, and business even when you're not around. It’s a constant source of engagement for potential customers.</p>
        </div>

        <div className=" p-6 rounded shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaUsers className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Attract More Customers</h3>
          <p className="text-gray-600">A website helps you reach a broader audience. Unlike a physical store, your website can be accessed from anywhere in the world, opening up new markets and expanding your customer base.</p>
        </div>

        <div className=" p-6 rounded-lg shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaDollarSign className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Increase Your Revenue</h3>
          <p className="text-gray-600">Your website is a powerful marketing tool. Through SEO strategies, you can drive traffic and increase visibility on search engines, leading to higher sales and more customers.</p>
        </div>

        <div className=" p-6 rounded-lg shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaChartLine className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Cost-Effective Marketing</h3>
          <p className="text-gray-600">Compared to traditional methods like print ads or billboards, a website offers an affordable way to reach a wider audience. It’s a great ROI with continuous results.</p>
        </div>

        <div className=" p-6 rounded-lg shadow-none">
          <div className="flex justify-center items-center mb-4">
            <FaComments className="h-8 w-8 text-gray-500" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Customer Insights and Feedback</h3>
          <p className="text-gray-600">Your website provides valuable insights into customer behavior. Analytics, feedback forms, and surveys can help you continuously improve your offerings and customer experience.</p>
        </div>
      </div>

      <div className="mt-12 text-center">
        <a
          href="/contact"
          className="bg-blue-600 text-white px-6 py-3 rounded-lg text-xl font-semibold hover:bg-blue-700 transition duration-300"
        >
          Get Started Today
        </a>
      </div>
    </div>
  );
};
