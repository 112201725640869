import React from "react";
import { Title } from "./Title";

<Title title={"About Us"} />

export const About = () => {
  return (
    <section className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 py-16 px-6 md:px-16 lg:px-24">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">About Winagencydesigns</h1>
        <p className="text-lg text-gray-600 dark:text-gray-400">
          Transforming ideas into impactful digital solutions.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
        <div className="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg"
            alt="Enhance Your Brand"
            className="rounded-lg w-full h-80 object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Enhance Your Brand</h2>
          <p className="text-gray-600 dark:text-gray-400">
            Visually stunning websites that reflect your unique identity.
          </p>
        </div>

        <div className="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg"
            alt="Boost Online Visibility"
            className="rounded-lg w-full h-80 object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Boost Online Visibility</h2>
          <p className="text-gray-600 dark:text-gray-400">
            SEO-friendly designs to reach your audience effortlessly.
          </p>
        </div>

        <div className="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg"
            alt="Drive Conversions"
            className="rounded-lg w-full h-80 object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Drive Conversions</h2>
          <p className="text-gray-600 dark:text-gray-400">
            Turn visitors into loyal customers with strategic designs.
          </p>
        </div>

        <div className="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184298/pexels-photo-3184298.jpeg"
            alt="Streamline Operations"
            className="rounded-lg w-full h-80  object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Streamline Operations</h2>
          <p className="text-gray-600 dark:text-gray-400">
            User-friendly interfaces that simplify experiences.
          </p>
        </div>

        <div className="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg"
            alt="Adapt to Growth"
            className="rounded-lg w-full h-80 object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Adapt to Growth</h2>
          <p className="text-gray-600 dark:text-gray-400">
            Scalable solutions for your growing business.
          </p>
        </div>

        <div class="flex flex-col items-center text-center">
          <img
            src="https://images.pexels.com/photos/3184358/pexels-photo-3184358.jpeg"
            alt="Client-Centered Focus"
            className="rounded-lg w-full h-80 object-cover mb-4"
          />
          <h2 className="text-xl font-semibold">Client-Centered Focus</h2>
          <p className="text-gray-600 dark:text-gray-400">
            Your goals guide everything we do.
          </p>
        </div>
      </div>

      <div className="text-center mt-12">
        <p className="text-lg text-gray-700 dark:text-gray-300">
          Ready to elevate your business? <span className="font-semibold">Let’s collaborate!</span>
        </p>
      </div>
    </section>
  );
};
