import sushi from '../assets/sushi.png'
import lawyer from '../assets/lawyer.png'
import premiumui from '../assets/premiumuikit.png'
import aianalytics from '../assets/aianalytics.png'
import portfolio from '../assets/portfolio.png'
import nature from '../assets/nature.png'
import aahandyman from '../assets/aahandyyman.png'
const projects = [
    {title: 'Sushi',img: sushi, link: 'https://www.freshsushibobatea.com/', category: 'Restuarant'},
    {title: 'Law Firm',img: lawyer, link: 'https://americanlegal.vercel.app/', category: 'Lawyer'},
    {title: 'Premium UI',img: premiumui, link: 'https://pageflows.vercel.app/', category: 'E-commerce'},
    {title: 'Personal portfolio',img: portfolio, link: 'https://businessone.vercel.app/', category: 'Personal portfolio'},
    {title: 'Ai Website',img: aianalytics, link: 'https://analyticai.vercel.app/?last_month_sales_volume_min=0&last_month_sales_volume_max=50000', category: 'Ai'},
    {title: 'Photography',img: nature, link: 'https://photography-xi-red.vercel.app/', category: 'Photography'},
    {title: 'Handyman services',img: aahandyman, link: 'https://aahandyman.vercel.app/', category: 'Handyman Services'},
]

export default projects