import React from "react";
import FreshSushiBoba from "../assets/freshsushilogo.png";
import Aahandyman from "../assets/Aahandyman.png";
import AmericanLogo from "../components/AmericanLogo.svg";
import AnalyticAi from "../assets/analyticAi.png";
import PremiumUiLogo from '../components/premiumui.svg';
import { Title } from "./Title";

export const Clients = () => {
  const clients = [
    {
      name: "Fresh Sushi Boba Tea Co.",
      img: FreshSushiBoba,
      website: "https://www.freshsushibobatea.com/",
    },
    {
      name: "American Legal",
      img: AmericanLogo,
      website: "https://americanlegal.vercel.app/",
    },
    {
      name: "Premium UI Kits",
      img: PremiumUiLogo,
      website: "https://pageflows.vercel.app/",
    },
    {
      name: "AnalyticAI",
      img: AnalyticAi,
      website:
        "https://analyticai.vercel.app/?last_month_sales_volume_min=0&last_month_sales_volume_max=100000",
    },
    {
      name: "AAhandyman",
      img: Aahandyman,
      website: "https://aahandyman.vercel.app/",
    },
  ];

  return (
    <div className="my-10 px-4 md:px-10">
  
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 py-10">
        {clients.map((client, index) => (
          <a
            key={index}
            href={client.website}
            target="_blank"
            rel="noreferrer"
            className="group flex flex-col items-center text-center space-y-2"
          >
            <div className="relative">
              <img
                className="w-20 h-24 object-contain transition-transform duration-300 group-hover:scale-105"
                src={client.img}
                alt={`${client.name} logo`}
                title={client.name}
              />
              <span className="absolute bottom-[-2rem] left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-black text-white text-sm px-2 py-1 rounded">
                {client.name}
              </span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
