import React from 'react'
import blogs from './blogsData'
import { useNavigate } from 'react-router-dom'
export const AllBlogs = () => {
const navigate = useNavigate()
  return (
    <div className='p-10 max-w-7xl mx-auto'>
        <h1 className='text-3xl text-center font-bold pb-4'>Read all Blogs</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogs
          .map((blog) => (
            <a href={blog.link}  onClick={() =>
                navigate(`${blog.link}`, { state: { blog } }) // Pass blog data as state
              }>
              <article className="rounded-lg">
                <div className="">
                  <img
                    className="rounded-xl h-80 w-full object-cover"
                    src={blog.image}
                    alt=""
                  />
                  <div className="">
                    <h2 className="text-3xl font-semibold mb-4">
                      {blog.title}
                    </h2>
                    <p className="text-gray-700 mb-4">{blog.description}</p>
                    <p className="font-sm text-gray-600">{blog.readTime}</p>
                  </div>
                </div>
              </article>
            </a>
          ))}
      </div>
    </div>
  )
}
