import React from "react";
import { motion } from "framer-motion";
import { Greendot } from "./Greendot";
import ScrollAnimation from "./ScrollAnimation";
import { FaStar } from "react-icons/fa";

export const Hero = () => {
  return (
    <div className="px-4 relative py-10 lg:h-[700px] flex flex-col bg-[#001D19] text-white">
      <div className="relative z-10 max-w-7xl mx-auto text-center">
        {/* Animated Heading Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          className="py-4 lg:py-8"
        >
          <p className="text-4xl pb-4 lg:pb-8 lg:text-7xl">
            Helping Businesses Succeed Online with{" "}
            <span className="gradient-text"> Stunning Websites</span> and{" "}
            <span className="gradient-text"> Tailored Solutions</span>.
          </p>
          <p className="opacity-60 text-xl lg:text-xl lg:w-1/2 mx-auto">
            Your search for the perfect web agency ends here. Let’s partner to
            bring your vision to life and craft something extraordinary
            together. Based in Utah, we proudly serve clients across the globe.
          </p>
        </motion.div>

        {/* Animated Client Thumbnails */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="flex justify-center"
        >
          <div className="shrink-0">
            <img
              className="w-10 h-10 shrink-0 object-cover rounded-full border-[#44857c] border"
              src="https://images.pexels.com/photos/713312/pexels-photo-713312.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <div className="shrink-0 -translate-x-2">
            <img
              className="w-10 h-10 shrink-0 object-cover rounded-full border-[#44857c] border"
              src="https://images.pexels.com/photos/1090387/pexels-photo-1090387.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <div className="shrink-0 -translate-x-4">
            <img
              className="w-10 h-10 shrink-0 object-cover rounded-full border-[#44857c] border"
              src="https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            className="shrink-0 -translate-x-6 bg-gray-100 text-black rounded-full text-xs p-2 flex items-center justify-center w-10 h-10"
          >
            10+
          </motion.div>
          <div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.2 }}
              className="flex items-center space-x-2 text-orange-500"
            >
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </motion.div>
            <p>From happy customers</p>
          </div>
        </motion.div>

        {/* Call-to-Action */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.5 }}
          className="py-4 my-8"
        >
          <a
            href="/contact"
            className="bg-red-500 text-white hover:bg-gray-200 hover:text-black px-5 py-5 mt-4 rounded-full"
          >
           Start now
          </a>
        </motion.div>
      </div>

      {/* Animated Testimonials */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 1 }}
        className="lg:flex space-x-4 justify-center hidden"
      >
        <div className="bg-white translate-y-10 text-black w-80 h-auto rounded-xl p-4 shadow-b-xl">
          <p className="text-lg font-semibold">Top-notch Website Design!</p>
          <p className="mt-2">
            "WinAgencyDesigns transformed our online presence with a sleek and
            modern website. The team is creative and professional!"
          </p>
          <p className="mt-4 text-right">
            - Sarah Mitchell, CEO of Digital Ventures
          </p>
        </div>
        <div className="bg-white translate-y-16 text-black w-80 h-auto rounded-xl p-4 shadow-b-2xl">
          <p className="text-lg font-semibold">Exceeded Expectations!</p>
          <p className="mt-2">
            "From concept to launch, WinAgencyDesigns delivered a site that
            perfectly represents our brand. Couldn't be happier with the
            result!"
          </p>
          <p className="mt-4 text-right">
            - David Green, Founder of EcoTech Solutions
          </p>
        </div>
        <div className="bg-white translate-y-10 text-black w-80 h-auto rounded-xl p-4 shadow-b-xl">
          <p className="text-lg font-semibold">Creative and Reliable</p>
          <p className="mt-2">
            "Their innovative approach to web design helped us stand out in a
            crowded market. WinAgencyDesigns is the best choice for any
            business!"
          </p>
          <p className="mt-4 text-right">
            - Emily Carter, Marketing Director at FreshStart
          </p>
        </div>
      </motion.div>
    </div>
  );
};
