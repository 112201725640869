import React, { useState } from "react";
import projects from "../components/project"; // Assume this has `category` field for each project
import { Helmet } from "react-helmet-async";
import { motion, AnimatePresence } from "framer-motion";

export const MoreWork = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  const categories = ["All", ...new Set(projects.map((project) => project.category))];

  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="This is my project page that shows what different projects I finished for different clients in Web Development."
        />
      </Helmet>
      <h1 className="text-2xl lg:text-4xl font-bold text-center mb-12 text-gray-800">
        Get the website of your dreams
        <br />
        We build websites for different categories — from local business to ecommerce
      </h1>

      {/* Filter Buttons */}
      <div className="relative px-4">
        <div className="flex gap-4 overflow-x-auto whitespace-nowrap scroll-snap-x p-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-semibold transition ${
                activeCategory === category
                  ? "bg-orange-500 text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-orange-500 hover:text-white"
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Project Grid */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
      >
        <AnimatePresence>
          {filteredProjects.map((project, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="group relative overflow-hidden rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:shadow-2xl transition-shadow duration-300"
            >
              {/* Project Image */}
              <img
                src={project.img}
                alt={`Project ${index + 1}`}
                className="w-full h-64 object-cover transition-transform duration-300 transform group-hover:scale-105"
              />

              {/* Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end">
                <div className="p-4">
                  <p className="text-white text-xl font-bold truncate mb-2">
                    {project.title || `Project ${index + 1}`}
                  </p>
                  <p className="text-gray-100 text-base mb-4">
                    {project.description || "Click below to visit the site."}
                  </p>
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block text-center text-sm text-white font-semibold bg-orange-500 px-6 py-2 rounded-full hover:bg-orange-600 transition-all duration-300"
                  >
                    Preview Site
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
