import React from "react";
import { useLocation } from "react-router-dom";

export const BlogDetails = () => {
  const location = useLocation();
  const { blog } = location.state || {}; // Access the passed blog data

  if (!blog) {
    return <p>No blog data available.</p>;
  }

  return (
    <div className="py-10 max-w-7xl mx-auto px-4">
      <h1 className="text-4xl pb-4 text-center">{blog.title}</h1>
      <img className="rounded-xl mb-4 h-96 w-full object-cover" src={blog.image} alt="" />
      <h3 className="italic text-2xl lg:w-3/4 mx-auto pb-4">{blog.details.intro}</h3>
      <ul className="lg:pl-6 lg:w-3/4 mx-auto">
        {blog.details.points.map((point, index) => (
          <li key={index} className="mb-2">
            {point.main ? <p>{point.main}</p> : <p>{point}</p>} {/* Conditionally render main point */}
            {point.sub && point.sub.length > 0 && (
              <ul className="pl-10 py-3 list-decimal">
                {point.sub.map((sub, subIndex) => (
                  <li key={subIndex}>{sub}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <p className="py-4 lg:w-3/4 mx-auto">{blog.details.conclusion}</p>
    </div>
  );
};
